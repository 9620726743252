/* eslint-env browser */
import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import useStudyTypeNames from "../../../../components/hooks/useStudyTypeNames.jsx";

function StudySettingsConfirmation({
  // Props
  newSettings,
  oldSettings,
  children,
}) {
  const displayableStudyTypes = useStudyTypeNames();

  const displayedSettingsField = React.useMemo(() => {
    return {
      studyType: "Study Type",
      studyDays: "Total Study Duration",
      tachyBpm: "Tachycardia Threshold",
      bradyBpm: "Bradycardia Threshold",
      pauseDuration: "Pause Threshold",
      episodeDuration: "Episode Duration Threshold",
      hpFilter: "High Pass Filter",
      lpFilter: "Low Pass Filter",
      notchFilter: "Notch Filter",
      deviceConfig: "Device Configuration",
      tzSerial: "Device",
    };
  }, []);

  const changedKeys = React.useMemo(() => {
    // Compare old settings values with new settings values and add pair to list if different
    const keysToDisplay = Object.keys(newSettings).filter((settingsKey) => {
      let oldEqualsNew = false;

      // Don't need to display study note change
      if (settingsKey === "studyNote") {
        oldEqualsNew = true;
      } else if (settingsKey === "deviceConfig") {
        oldEqualsNew = oldSettings[settingsKey]?.name === newSettings[settingsKey].name;
      } else if (typeof oldSettings[settingsKey] === "number") {
        oldEqualsNew = oldSettings[settingsKey] === Number(newSettings[settingsKey]);
      } else {
        oldEqualsNew = oldSettings[settingsKey] === newSettings[settingsKey];
      }
      return !oldEqualsNew;
    });

    return keysToDisplay;
  }, [newSettings, oldSettings]);

  //---------------------------------------------------------------------------
  // Rendering
  //---------------------------------------------------------------------------
  return (
    <Grid container spacing={1}>
      {children}
      {changedKeys.map((key) => (
        <React.Fragment key={key}>
          {key === "studyType" && (
            <Grid size={12}>
              <Typography variant="p">
                <b>{displayedSettingsField[key]}:</b>{" "}
                <i>
                  {displayableStudyTypes[oldSettings[key]] || "N/A"} →{" "}
                  {displayableStudyTypes[newSettings[key]]}
                </i>
              </Typography>
            </Grid>
          )}
          {key === "deviceConfig" && (
            <Grid size={12}>
              <Typography variant="p">
                <b>{displayedSettingsField[key]}:</b>{" "}
                <i>
                  {oldSettings[key]?.name || "N/A"} → {newSettings[key].name}
                </i>
              </Typography>
            </Grid>
          )}
          {key !== "studyType" && key !== "deviceConfig" && (
            <Grid size={12}>
              <Typography variant="p">
                <b>{displayedSettingsField[key]}:</b>{" "}
                <i>
                  {oldSettings[key] || "N/A"} → {newSettings[key]}
                </i>
              </Typography>
            </Grid>
          )}
        </React.Fragment>
      ))}
    </Grid>
  );
}

StudySettingsConfirmation.propTypes = {
  newSettings: PropTypes.object.isRequired,
  oldSettings: PropTypes.object.isRequired,
  children: PropTypes.node,
};

export default StudySettingsConfirmation;
