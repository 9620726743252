/* eslint-env browser */
import React from "react";
import {FormProvider, useForm, useFormState} from "react-hook-form";
import {DateTime} from "luxon";
import {useConfirm} from "material-ui-confirm";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import Smartphone from "@mui/icons-material/Smartphone";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import LoadingButton from "@mui/lab/LoadingButton";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";

//---------------------------------------------------------------------------
// TZ Components
//---------------------------------------------------------------------------
import {useInterval} from "@tzmedical/react-hooks";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import axios from "../../../../axiosClient.js";
import useJwt from "../../../../components/hooks/useJwt.jsx";
import Alert from "../../../../shared/react/Alert.jsx";
import CancelButton from "../../../../shared/react/CancelButton.jsx";
import IconWithText from "../../../../shared/react/IconWithText.jsx";
import TableLoading from "../../../../shared/react/TableLoading.jsx";
import CommentField from "../StudyActionComponents/CommentField.jsx";

// Unless we can get socket.io or long polling working, fetching the data
// every 15 seconds should keep things from getting "stale"

const DATA_REFRESH_INTERVAL_MS = 15000;
function EndStudyForm({
  // Props
  study,
  enrollmentToUse,
  handleClose,
}) {
  const {fullName} = useJwt();

  const [error, setError] = React.useState(null);

  // can only send message if there is no unlinked send message actions
  const [canEndStudy, setCanEndStudy] = React.useState(false);

  const [loading, setLoading] = React.useState(true);
  const [submitting, setSubmitting] = React.useState(false);

  const {control, handleSubmit} = useForm();
  const {isDirty} = useFormState({control});

  //---------------------------------------------------------------------------
  // Set up hook for confirmation dialogs
  //---------------------------------------------------------------------------
  const confirm = useConfirm();

  //---------------------------------------------------------------------------
  // Load data from the API
  //---------------------------------------------------------------------------
  const getEndStudyData = React.useCallback(async () => {
    try {
      const {data: endStudyActionsResponse} = await axios({
        url: "/actions",
        method: "get",
        params: {
          deviceId: enrollmentToUse.deviceId,
          subType: "endStudy",
          enrollmentId: {$or: ["null", enrollmentToUse.enrollmentId]}, // Include null enrollmentIds (shown for all enrollments)
          status: {$or: ["sent", "pending"]},
        },
      });

      // Users can only send an end study action if there are no pending or sent end study actions
      if (endStudyActionsResponse.length === 0) {
        setCanEndStudy(true);
      }
    } catch (err) {
      setError(err.message);
    }

    setLoading(false);
  }, [enrollmentToUse]);

  useInterval(getEndStudyData, DATA_REFRESH_INTERVAL_MS, loading);

  //---------------------------------------------------------------------------
  // Submitting form
  //---------------------------------------------------------------------------
  const onSubmit = React.useCallback(
    async (data) => {
      setSubmitting(true);

      const confirmationMessage = (
        <>
          {!study.followUpStudyAssociation && (
            <Alert
              message="Confirming this action will send a request to the device to end the study and no more data will be recorded."
              level="warning"
            />
          )}
          {!!study.followUpStudyAssociation && (
            <Alert
              message={`Confirming this action will end the study and immediately transition the device into follow-up study ID ${study.followUpStudyAssociation.id}.`}
              level="warning"
            />
          )}

          <Grid container sx={{mt: 2}}>
            <Grid size={12}>
              <Typography variant="body2">
                <b>Study ID</b>: {study.id}
              </Typography>
            </Grid>
            {study.studyDetails.patientName &&
              study.studyDetails.patientName !== enrollmentToUse.tzSerial && (
                <Grid size={12}>
                  <Typography variant="body2">
                    <b>Patient Name</b>: {study.studyDetails.patientName}
                  </Typography>
                </Grid>
              )}
            <Grid size={12}>
              <Typography variant="body2">
                <b>Device</b>: {enrollmentToUse.tzSerial}
              </Typography>
            </Grid>
          </Grid>
        </>
      );

      try {
        await confirm({
          title: "End this study?",
          content: confirmationMessage,
          confirmationText: "End Study",
        });
      } catch (err) {
        // If the user cancelled the confirmation, do nothing
        setSubmitting(false);
        return;
      }

      try {
        if (!study.followUpStudyAssociation) {
          const endStudyAction = {
            deviceId: enrollmentToUse.deviceId,
            enrollmentId: enrollmentToUse.enrollmentId,
            facilityId: study.facilityId,
            createdBy: fullName,
            comment: data.comment,
            data: "",
          };

          await axios({
            url: "/actions/endStudy",
            method: "post",
            data: endStudyAction,
          });
        } else {
          const {
            data: [followUpEnrollment],
          } = await axios({
            url: "/enrollments",
            method: "get",
            params: {studyId: study.followUpStudyAssociation.id},
          });

          // If this study has a follow-up, instead of ending the recording on the device,
          // immediately transition into the follow-up and shorten the recording duration
          // so that the follow-up study is not longer than intended
          const durationRecorded = DateTime.now()
            .diff(DateTime.fromISO(study.studyStartDate), "hours")
            .toObject().hours;
          const totalShortenedDuration = Math.ceil(
            durationRecorded + study.followUpStudyAssociation.followUp.pendingDuration
          );

          const studyTransitionAction = {
            deviceId: enrollmentToUse.deviceId,
            enrollmentId: enrollmentToUse.enrollmentId,
            facilityId: study.facilityId,
            createdBy: fullName,
            comment: data.comment,
            data: JSON.stringify({
              patientId: followUpEnrollment.deviceEnrollmentId,
              studyHours: totalShortenedDuration,
            }),
          };
          await axios({
            url: "/actions/study-transition",
            method: "post",
            data: studyTransitionAction,
          });
        }

        handleClose();
      } catch (err) {
        setError(err.message);
      }

      setSubmitting(false);
    },
    [confirm, fullName, enrollmentToUse, study, handleClose]
  );

  //---------------------------------------------------------------------------
  // Rendering
  //---------------------------------------------------------------------------
  return (
    <>
      {loading && <TableLoading />}
      {!loading && (
        <TabPanel value="endStudy" data-cy="end-study">
          <Alert message={error} setMessage={setError} level="error" variant="snackbar" />

          {!canEndStudy && (
            <Alert
              message="An end study action is already being sent to the device."
              level="warning"
              otherProps={{mb: 2}}
            />
          )}

          <Grid container spacing={3} sx={{alignItems: "center"}}>
            <Grid size={12}>
              <IconWithText
                icon={<Smartphone color="tertiary" />}
                text={<Typography variant="body2">{enrollmentToUse.tzSerial}</Typography>}
              />
            </Grid>

            <FormProvider {...{control}}>
              <Grid size={12}>
                <CommentField disabled={!canEndStudy} />
              </Grid>
            </FormProvider>

            <Grid size={12} sx={{display: "inline-flex", justifyContent: "flex-end"}}>
              <Box sx={{mx: 3}}>
                <CancelButton
                  color="secondary"
                  isDirty={isDirty}
                  onClick={handleClose}
                  data-cy="cancel-action-button"
                >
                  Cancel
                </CancelButton>
              </Box>
              <LoadingButton
                data-cy="submit-action-button"
                disabled={submitting || !canEndStudy || !isDirty}
                variant="contained"
                color="secondary"
                loading={submitting}
                onClick={handleSubmit(onSubmit)}
              >
                End Study
              </LoadingButton>
            </Grid>
          </Grid>
        </TabPanel>
      )}
    </>
  );
}

EndStudyForm.propTypes = {
  study: PropTypes.object.isRequired,
  enrollmentToUse: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default EndStudyForm;
