/* eslint-env browser */
import React from "react";
import {FormProvider, useForm, useFormState} from "react-hook-form";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import Smartphone from "@mui/icons-material/Smartphone";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import LoadingButton from "@mui/lab/LoadingButton";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";

//---------------------------------------------------------------------------
// TZ Components
//---------------------------------------------------------------------------
import {useInterval} from "@tzmedical/react-hooks";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import axios from "../../../../axiosClient.js";
import useJwt from "../../../../components/hooks/useJwt.jsx";
import Alert from "../../../../shared/react/Alert.jsx";
import CancelButton from "../../../../shared/react/CancelButton.jsx";
import FormStringInput from "../../../../shared/react/FormStringInput.jsx";
import IconWithText from "../../../../shared/react/IconWithText.jsx";
import TableLoading from "../../../../shared/react/TableLoading.jsx";
import CommentField from "../StudyActionComponents/CommentField.jsx";

// Unless we can get socket.io or long polling working, fetching the data
// every 15 seconds should keep things from getting "stale"
const DATA_REFRESH_INTERVAL_MS = 15000;

function SendMessageForm({
  // Props
  study,
  enrollmentToUse,
  handleClose,
}) {
  const {fullName} = useJwt();

  const [error, setError] = React.useState(null);

  // can only send message if there is no unlinked send message actions
  const [canSendNewMessage, setCanSendNewMessage] = React.useState(false);
  const [defaultValue, setDefaultValue] = React.useState("");

  const [messageOptions, setMessageOptions] = React.useState([]);

  const [loading, setLoading] = React.useState(true);
  const [submitting, setSubmitting] = React.useState(false);

  const {control, handleSubmit} = useForm();
  const {isDirty} = useFormState({control});

  //---------------------------------------------------------------------------
  // Load data from the API
  //---------------------------------------------------------------------------
  const getMessageData = React.useCallback(async () => {
    try {
      const [{data: messageResponse}, {data: unlinkedActionsResponse}] = await Promise.all([
        axios({
          url: "/deviceMessageOptions",
          method: "get",
          params: {facilityId: study.facilityId},
        }),
        axios({
          url: "/actions",
          method: "get",
          params: {
            deviceId: enrollmentToUse.deviceId,
            subType: "sendMessage",
            enrollmentId: {$or: ["null", enrollmentToUse.enrollmentId]}, // Include null enrollmentIds (shown for all enrollments)
            linked: false,
          },
        }),
      ]);

      let selectedMessage = "";
      if (unlinkedActionsResponse.length === 0) {
        setCanSendNewMessage(true);
      } else {
        selectedMessage = unlinkedActionsResponse[0].originalData;
      }

      const options = messageResponse.map((message) => ({...message, name: message.message}));

      setDefaultValue(selectedMessage);
      setMessageOptions(options);
    } catch (err) {
      setError(err.message);
    }

    setLoading(false);
  }, [study, enrollmentToUse]);

  useInterval(getMessageData, DATA_REFRESH_INTERVAL_MS, loading);

  //---------------------------------------------------------------------------
  // Submitting form
  //---------------------------------------------------------------------------
  const onSubmit = React.useCallback(
    async (data) => {
      setSubmitting(true);

      const actionData = {
        deviceId: enrollmentToUse.deviceId,
        enrollmentId: enrollmentToUse.enrollmentId,
        facilityId: study.facilityId,
        createdBy: fullName,
        comment: data.comment,
        data: data.message,
      };

      try {
        await axios({
          url: "/actions/sendMessage",
          method: "post",
          data: actionData,
        });

        handleClose();
      } catch (err) {
        setError(err.message);
      }
      setSubmitting(false);
    },
    [fullName, enrollmentToUse, study, handleClose]
  );

  //---------------------------------------------------------------------------
  // Rendering
  //---------------------------------------------------------------------------
  return (
    <>
      {loading && <TableLoading />}
      {!loading && (
        <TabPanel value="sendMessage" data-cy="send-message">
          <Alert message={error} setMessage={setError} level="error" variant="snackbar" />
          {!canSendNewMessage && (
            <Alert
              message="Please wait until the current message has been received by the patient before sending another."
              level="warning"
              otherProps={{mb: 2}}
            />
          )}

          <Grid container spacing={3} sx={{alignItems: "center"}}>
            <FormProvider {...{control}}>
              <Grid size={12}>
                <IconWithText
                  icon={<Smartphone color="tertiary" />}
                  text={<Typography variant="body2">{enrollmentToUse.tzSerial}</Typography>}
                />
              </Grid>

              <Grid size={12}>
                <FormStringInput
                  control={control}
                  defaultValue={defaultValue}
                  label="Message"
                  name="message"
                  data-cy="message-select"
                  disabled={!canSendNewMessage}
                  options={messageOptions}
                  rules={{required: "Message is required"}}
                  size="small"
                  otherProps={{variant: "outlined"}}
                />
              </Grid>
              <Grid size={12}>
                <CommentField disabled={!canSendNewMessage} />
              </Grid>
            </FormProvider>

            <Grid size={12} sx={{display: "inline-flex", justifyContent: "flex-end"}}>
              <Box sx={{mx: 3}}>
                <CancelButton
                  color="secondary"
                  isDirty={isDirty}
                  onClick={handleClose}
                  data-cy="cancel-action-button"
                >
                  Cancel
                </CancelButton>
              </Box>
              <LoadingButton
                data-cy="submit-action-button"
                disabled={submitting || !canSendNewMessage || !isDirty}
                variant="contained"
                color="secondary"
                loading={submitting}
                onClick={handleSubmit(onSubmit)}
              >
                Send Message
              </LoadingButton>
            </Grid>
          </Grid>
        </TabPanel>
      )}
    </>
  );
}

SendMessageForm.propTypes = {
  study: PropTypes.object.isRequired,
  enrollmentToUse: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default SendMessageForm;
