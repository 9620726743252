/* eslint-env browser */
import React from "react";
import {FormProvider, useForm, useFormState} from "react-hook-form";
import {useConfirm} from "material-ui-confirm";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import Assignment from "@mui/icons-material/Assignment";
import Smartphone from "@mui/icons-material/Smartphone";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import LoadingButton from "@mui/lab/LoadingButton";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import axios from "../../../../axiosClient.js";
import useStudyTypeNames from "../../../../components/hooks/useStudyTypeNames.jsx";
import {useStudiesDispatch} from "../../../../contexts/StudiesContext.jsx";
import Alert from "../../../../shared/react/Alert.jsx";
import CancelButton from "../../../../shared/react/CancelButton.jsx";
import IconWithText from "../../../../shared/react/IconWithText.jsx";
import StartDateTimeInput from "../../../../shared/react/StartDateTimeInput.jsx";
import CommentField from "../StudyActionComponents/CommentField.jsx";

function UpdateStartDateForm({
  // Props
  study,
  enrollmentToUse,
  handleClose,
}) {
  const displayableStudyTypes = useStudyTypeNames();
  const [error, setError] = React.useState(null);
  const [submitting, setSubmitting] = React.useState(false);

  //---------------------------------------------------------------------------
  // Set up hook for confirmation dialogs
  //---------------------------------------------------------------------------
  const confirm = useConfirm();

  //---------------------------------------------------------------------------
  // Submitting form
  //---------------------------------------------------------------------------
  const {handleSubmit, control, setValue, watch} = useForm();
  const {isDirty} = useFormState({control});

  const dispatch = useStudiesDispatch();

  const onSubmit = React.useCallback(
    async (data) => {
      setSubmitting(true);
      try {
        const {studyStartDate} = data;

        let clickedCancel = false;

        try {
          await confirm({
            title: "Update study start date?",
            content: (
              <Alert
                message="NOTE: This will not update the device's settings, only the study start date in BitRhythm."
                level="warning"
              />
            ),
            confirmationText: "Update",
          });
        } catch (err) {
          clickedCancel = true;
        }
        if (!clickedCancel) {
          try {
            await axios({
              url: `/studies/${study.id}/start-date`,
              method: "post",
              data: {studyStartDate, note: data.comment},
            });

            const {
              data: [updatedStudy],
            } = await axios({
              method: "get",
              url: "/studies",
              params: {id: study.studyId || study.id},
            });

            dispatch({type: "updated", updatedElement: updatedStudy});
            handleClose();
          } catch (err) {
            setError(err.message);
          }
        }
      } catch (err) {
        if (err?.response?.data?.title) {
          setError(err.response.data.title);
        } else {
          setError(err.message);
        }
      }

      setSubmitting(false);
    },
    [confirm, dispatch, handleClose, study.id, study.studyId]
  );

  //---------------------------------------------------------------------------
  // Rendering
  //---------------------------------------------------------------------------
  return (
    <TabPanel value="updateStartDate" data-cy="update-start-date">
      <Alert message={error} setMessage={setError} level="error" />
      <Grid container spacing={3} sx={{alignItems: "center"}}>
        <Grid size={8} container>
          <Grid size={3}>
            <IconWithText
              icon={<Smartphone color="tertiary" />}
              text={<Typography variant="body2">{enrollmentToUse.tzSerial}</Typography>}
            />
          </Grid>
          <Grid size={3}>
            <IconWithText
              icon={<Assignment color="tertiary" />}
              text={<Typography variant="body2">{displayableStudyTypes[study.studyType]}</Typography>}
            />
          </Grid>
        </Grid>
        <FormProvider {...{control, watch, setValue}}>
          <Grid size={4}>
            <StartDateTimeInput
              control={control}
              study={study}
              label="Date and Time"
              variant="outlined"
              required
            />
          </Grid>
          <Grid size={12}>
            <CommentField />
          </Grid>
        </FormProvider>

        <Grid size={12} sx={{display: "inline-flex", justifyContent: "flex-end"}}>
          <Box sx={{mx: 3}}>
            <CancelButton
              color="secondary"
              isDirty={isDirty}
              onClick={handleClose}
              data-cy="cancel-action-button"
            >
              Cancel
            </CancelButton>
          </Box>
          <LoadingButton
            data-cy="submit-action-button"
            disabled={submitting || !isDirty}
            variant="contained"
            color="secondary"
            loading={submitting}
            onClick={handleSubmit(onSubmit)}
          >
            Change Study Start Date
          </LoadingButton>
        </Grid>
      </Grid>
    </TabPanel>
  );
}

UpdateStartDateForm.propTypes = {
  study: PropTypes.object.isRequired,
  enrollmentToUse: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default UpdateStartDateForm;
