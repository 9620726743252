/* eslint-env browser */
import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import ActionsTableRow from "./ActionsTableRow.jsx";

function StudyActionsTable({
  // Props
  allActions,
  actions,
  setActions,
  study,
  tableType,
  deviceHasBeenReplaced,
  setLoadingState,
  setError,
}) {
  //---------------------------------------------------------------------------
  // Rendering
  //---------------------------------------------------------------------------
  return (
    <>
      <Divider sx={{bgcolor: "secondary.main"}} />
      <DialogContent sx={{paddingTop: 0}}>
        <Typography variant="h6" sx={{m: 2}}>
          {`${tableType === "sent" ? "Sent" : "Pending"} Actions`}
        </Typography>
        <Table size="small" data-cy={`${tableType}-actions-table`}>
          <TableHead>
            <TableRow>
              <TableCell>Action Name</TableCell>
              <TableCell>Comment</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>Created By</TableCell>
              <TableCell width="10%" />
            </TableRow>
          </TableHead>

          <TableBody>
            {actions.map((action) => (
              <ActionsTableRow
                key={action.id}
                action={action}
                actions={actions}
                setActions={setActions}
                allActions={allActions}
                study={study}
                tableType={tableType}
                deviceHasBeenReplaced={deviceHasBeenReplaced}
                setParentLoadingState={setLoadingState}
                setError={setError}
              />
            ))}
          </TableBody>
        </Table>
      </DialogContent>
    </>
  );
}

StudyActionsTable.propTypes = {
  allActions: PropTypes.array,
  actions: PropTypes.array.isRequired,
  setActions: PropTypes.func.isRequired,
  study: PropTypes.object.isRequired,
  tableType: PropTypes.string.isRequired,
  deviceHasBeenReplaced: PropTypes.bool,
  setLoadingState: PropTypes.func,
  setError: PropTypes.func,
};

export default StudyActionsTable;
