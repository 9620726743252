/* eslint-env browser */
import React from "react";
import {useFormContext} from "react-hook-form";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Box from "@mui/material/Box";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import FormStringInput from "../../../../shared/react/FormStringInput.jsx";

function CommentField({
  // Props
  disabled = false,
}) {
  const [charCount, setCharCount] = React.useState(0);

  //---------------------------------------------------------------------------
  // Retrieve all hook methods from the controlled form
  //---------------------------------------------------------------------------
  const {control} = useFormContext();

  //---------------------------------------------------------------------------
  // Character count callback
  //---------------------------------------------------------------------------
  const handleCharCountChange = React.useCallback((event) => {
    setCharCount(event.target.value.length);
  }, []);

  //---------------------------------------------------------------------------
  // Rendering
  //---------------------------------------------------------------------------
  return (
    <Box onChange={handleCharCountChange}>
      <FormStringInput
        control={control}
        defaultValue=""
        label="Comment"
        disabled={disabled}
        name="comment"
        data-cy="comment-input"
        rules={{
          required: "Comment is required",
          maxLength: {value: 1000, message: "Comment exceeds max character limit"},
        }}
        size="small"
        otherProps={{multiline: true, rows: 3, variant: "outlined"}}
      />
      <Box sx={{fontSize: "0.75rem", justifyContent: "flex-end", display: "flex"}}>{charCount}/1000</Box>
    </Box>
  );
}

CommentField.propTypes = {
  disabled: PropTypes.bool,
};

export default CommentField;
